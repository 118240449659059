import React, { useState, useEffect } from 'react';

const Topics = () => {
  const [fbTopicsData, setfbTopicsData] = useState([]);

  const loadfbTopicsData = async() => {
    // Query the API Gateway
    const resp  = await fetch('https://xrqwynceq6.execute-api.us-west-2.amazonaws.com/Production/topics');
    let jsonData = await resp.json();

    // Assign response data to our state variable
    setfbTopicsData(jsonData);
 }

 useEffect(() => {
   // Load the Topics data from teh API Gateway
   loadfbTopicsData();
 }, []);


    return (
      <div className="scene" id="topics">
        <article className="heading">
          <h1>Topics</h1>
        </article>
        <article id="usefulinfo">
          
          <section className="checklist" id="topics">
            <p>Our topics are designed to get you on your way to building wealth and prosperity in a way that supports your current situation.</p>
            <ul>
              {
                fbTopicsData.map((topics) =>
                <ll>{topics.name}</ll>
                )
            }                   
            </ul>
          </section>
         
        </article>
        <article id="greenprogram">
          <h2>Build Wealth Program</h2>
          <p><strong>financialBlack</strong> is for those that desire to build wealth for themselves and their future generations.</p>
          </article>
      </div>
    )
}

export default Topics;