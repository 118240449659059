import React from 'react';
import Topics from './Topics.js';
import Welcome from './Welcome.js'

const Main = () => {
    return (
        <main id="wrapper">
            <Welcome />
            <Topics />       
        </main>
    )
}

export default Main;