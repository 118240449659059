import React, { useState, useEffect } from 'react';

//import mbgrill from './mbgrill.jpeg'


const Header = () => {
  const [fbMenuData, setfbMenuData] = useState([]);

  const loadfbMenuData = async() => {
    // Query the API Gateway
    const resp  = await fetch('https://o95bv1w17h.execute-api.us-west-2.amazonaws.com/Production/menu');
    let jsonData = await resp.json();

    // Assign response data to our state variable
    setfbMenuData(jsonData);
  }

useEffect(() => {
  // Load the menu links date from teh API Gateway
  loadfbMenuData();
}, []);
  
  return (
    <header id="intro">
      <article className="fullheight">
        <div className="hgroup">      
          <h1>financialBlack</h1>
          <h2>Get paid!</h2>
          <p><a href="#welcome"><img src="https://landonhotel.com/images/misc/arrow.png" alt="down arrow" /></a></p>
        </div>
      </article>

      <nav id="nav">
        <div className="navbar">
          <div className="brand"><a href="#welcome">financialBlack</a></div>
          <ul>
            {
              fbMenuData.map((link) =>
                <li><a className={`icon ${link.class}`} href={link.href}><span>{link.text}</span></a></li>
              )
            }
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;