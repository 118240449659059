import React, { useState, useEffect } from 'react';
//import fbImagesData from './data/fb_images.json'
const Welcome = () => {
  const [fbImagesData, setfbImagesData] = useState([]);

  const loadfbImagesData = async() => {
     // Query the API Gateway
     const resp  = await fetch('https://xrqwynceq6.execute-api.us-west-2.amazonaws.com/Production/images');
     let jsonData = await resp.json();
 
     // Assign response data to our state variable
     setfbImagesData(jsonData);
  }

  useEffect(() => {
    // Load the menu links date from teh API Gateway
    loadfbImagesData();
  }, []);
    
  return (
    <div className="scene" id="welcome">
      <article className="content">
        <div className="gallery">
          {
            fbImagesData.map((image) =>
              <img className={image.className} src={image.src} alt={image.alt} />
            )
          }
        </div>
        <h1 >Welcome to financialBlack!</h1>
        <p>Get your finances in order to enable a better, more enriching life!</p>
      </article>
    </div>
  );
}

export default Welcome;